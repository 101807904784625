<script setup lang="ts">
import { useHeader } from "~/composables/useHeader";

const { pushSuccess } = useNotificationsEx();

const { data: promotion, error } = await useMaasPromotion();
const { isMobileDrawerOpened } = useHeader();

const showHint = ref(false);

const toggleHint = () => {
  showHint.value = !showHint.value;
}

function copyPromotionCode(code: string) {
  navigator.clipboard.writeText(code);
  pushSuccess(`Der Code ${code} wurde in Ihre Zwischenablage kopiert.`, { timeout: 5000 })
}
</script>

<template>
  <div
    v-if="promotion?.promotions"
    v-for="promo in promotion.promotions"
    :key="promo.id"
    class="py-2 relative z-4 s-gutter text-small"
    :class="{
      'hidden': isMobileDrawerOpened,
      'text-maas-typography-headline-primary bg-maas-background-bright-sand': promo.color === 'sand',
      'text-maas-typography-headline-light bg-maas-background-primary': promo.color === 'primary',
      'text-maas-typography-headline-light bg-maas-background-red': promo.color === 'red',
    }"
  >
    <div class="c-width-default flex flex-wrap items-center justify-center gap-x-4 gap-y-1">
      <p v-if="promo.title" class="font-bold uppercase">{{ promo.title }}</p>

      <div class="flex items-center flex-wrap justify-center gap-x-4 gap-y-2">
        <div v-if="promo.code" class="flex items-center gap-2">
          <p class="uppercase">Code:</p>
          <p class="px-2 flex gap-1 items-center rounded bg-maas-background-default/15 uppercase cursor-pointer tracking-widest select-none" @click="copyPromotionCode(promo.code)">
            <code>{{ promo.code }}</code>
            <svg width="12" height="14" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-current">
              <path d="M12 4.858a.956.956 0 0 0-.04-.189v-.063a.752.752 0 0 0-.127-.196l-4-4.2a.713.713 0 0 0-.186-.133.203.203 0 0 0-.06 0A.568.568 0 0 0 7.367 0h-2.7c-.53 0-1.04.221-1.415.615A2.154 2.154 0 0 0 2.667 2.1v.7H2c-.53 0-1.04.221-1.414.615A2.154 2.154 0 0 0 0 4.9v7c0 .557.21 1.091.586 1.485C.96 13.779 1.47 14 2 14h5.333c.53 0 1.04-.221 1.415-.615s.585-.928.585-1.485v-.7H10c.53 0 1.04-.221 1.414-.615.375-.394.586-.928.586-1.485V4.858ZM8 2.387 9.727 4.2h-1.06a.651.651 0 0 1-.472-.205A.718.718 0 0 1 8 3.5V2.387ZM8 11.9c0 .186-.07.364-.195.495a.65.65 0 0 1-.472.205H2a.65.65 0 0 1-.471-.205.718.718 0 0 1-.196-.495v-7c0-.186.07-.364.196-.495A.65.65 0 0 1 2 4.2h.667v4.9c0 .557.21 1.091.585 1.485.376.394.884.615 1.415.615H8v.7Zm2.667-2.8c0 .186-.07.364-.196.495A.65.65 0 0 1 10 9.8H4.667a.651.651 0 0 1-.472-.205A.718.718 0 0 1 4 9.1v-7c0-.186.07-.364.195-.495a.651.651 0 0 1 .472-.205h2v2.1c0 .557.21 1.091.585 1.485.376.394.884.615 1.415.615h2v3.5Z" />
            </svg>
          </p>
        </div>

        <NuxtLink v-if="promo.button && promo.link" :href="promo.link" class="underline underline-offset-3 hover:decoration-none">{{ promo.button }}</NuxtLink>

        <svg v-if="promo.text" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer" @click.prevent="toggleHint">
          <g clip-path="url(#a)"><path class="fill-current" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Zm8-6.5a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13ZM6.5 7.75A.75.75 0 0 1 7.25 7h1a.75.75 0 0 1 .75.75v2.75h.25a.75.75 0 1 1 0 1.5h-2a.75.75 0 1 1 0-1.5h.25v-2h-.25a.75.75 0 0 1-.75-.75ZM8 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"/></g>
          <defs><clipPath id="a"><path class="fill-current" d="M0 0h16v16H0z"/></clipPath></defs>
        </svg>
      </div>
    </div>

    <div v-if="promo.text && showHint" v-html="promo.text" class="c-width-small flex justify-center py-2 richtext select-none"></div>
  </div>
</template>
