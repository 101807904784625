export const useMaasPromotion = async (cacheKey: string = "main") => {
  const { apiInstance } = useShopwareContext();

  return await useAsyncData(
    "maasPromotion" + cacheKey,
    async () => {
      const maasPromotion = await apiInstance.invoke.get("/store-api/maas-promotion-list");

      return maasPromotion.data;
    },
    {
      immediate: true,
    }
  );
};
